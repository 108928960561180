import { } from "../../node_modules/mmenu-light/src/mmenu-light";
export function offcanvas() {

	document.addEventListener(
		"DOMContentLoaded", () => {
			const menu = new MmenuLight(
				document.querySelector(".sitenav__wrap"),
				"(max-width: 600px)"
			);

			const navigator = menu.navigation({
				// options
			});

			const drawer = menu.offcanvas({
				// options
			});

			document.querySelector(".sitenav__toggle")
				.addEventListener("click", (evnt) => {
					evnt.preventDefault();
					drawer.open();
				});
		}
	);
}
